@import './fonts.css';

html,
#root {
  width: 100%;
  min-height: 100%;
  height: 100%;
}

body {
  height: 100% !important;
  padding: 0;
  overflow-y: scroll;
  margin: 0;
  background-color: #F3F3F4;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: auto;
  -webkit-tap-highlight-color: transparent;
}

body.swal2-height-auto{
  height: 100% !important;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-overflow-scrolling: touch;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}

.swal2-container{
  z-index: 1500!important;
}

.customSwitch .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked  {
  color: #fff!important
}
.customSwitch .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
  opacity: 1!important;
  background-color: red!important
}
.customSwitch .css-1nr2wod.Mui-checked + .MuiSwitch-track{
  opacity: 1!important;
  background-color: red!important
}

.customSwitch .css-1nr2wod.Mui-checked {
  color: #fff!important
}

 /* publiclayout */

 @media screen and (min-width: 1500px) {
  .public-textbox .MuiInputBase-input{
    height: 70px!important
  }
  .public-textbox .MuiFormControl-root{
    min-height: 120px!important;
  }
  /* .MuiButton-sizeMedium {
    min-height: 65px!important;
  } */
  .MuiButton-sizeMedium {
    min-height: 45px!important;
    min-width:  160px !important;
    font-size: 18px!important;
  }
  .appBtn {
    min-height: 45px!important;
    min-width:  160px !important;
    font-size: 18px!important;
  }
  
  .appBtnCancel {
    min-height: 45px!important;
    min-width:  160px !important;
    font-size: 18px!important;
    background-color: #A2AAAD !important
  }
}

@media screen and (max-width: 1500px) {
  
  .MuiButton-sizeMedium {
    min-height: 35px!important;
    min-width:  150px !important;
    font-size: 12px!important;
  }
  .appBtn {
    min-height: 35px!important;
    min-width:  150px !important;
    font-size: 12px!important;
  }
  
  .appBtnCancel {
    min-height: 35px!important;
    min-width:  150px !important;
    font-size: 12px!important;
    background-color: #A2AAAD !important
  }
}
.public-textbox .MuiInputBase-input{
  height: 50px
}

.public-textbox .MuiFormControl-root{
  min-height: 85px;
}





.MuiInputBase-inputAdornedEnd{
  padding-top: 4px!important;
}

.closeBtn{
  min-width:  unset !important;
  padding: unset!important;
  flex: none!important;
  margin-top: unset!important;
  min-height: unset !important
}

.theme-accordian.css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
  background: unset;
}

.rsm-svg {
  height: 670px;
  width: 885px;
}

.email-style .react-multi-email [data-tag] {
  display: inline-block;
  line-height: 1;
  vertical-align: baseline;
  margin: 0 0.14285714em;
  background-color: #C0C0C0;
  background-image: none;
  padding: 0.5833em 0.833em;
  color: rgba(0, 0, 0, 0.6);
  text-transform: none;
  font-weight: 600;
  border: 0 solid transparent;
  border-radius: 0.28571429rem;
  -webkit-transition: background 0.1s ease; 
  -o-transition: background 0.1s ease;
  transition: background 0.1s ease;
  font-size: 0.8rem;
  margin-top: 10px;
  font-family: "Roboto";
}

.email-style .react-multi-email [data-tag] [data-tag-handle] {
  margin-left: 0.833em;
  cursor: pointer;
  font-family: "Roboto";
}

.email-style .react-multi-email {
  margin: 0;
  max-width: 500px;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.21428571em;
  padding: 0.4em 0.5em;
  background: #f0f0f0;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  -webkit-transition: box-shadow 0.1s ease, border-color 0.1s ease;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  font-size: 13px;
  position: relative;
  font-family: "Roboto";
}

.email-style .react-multi-email > input {
  width: 100% !important;
  outline: none !important;
  border: 0 none !important;
  display: inline-block !important;
  line-height: 1;
  vertical-align: baseline !important;
  padding: 0.4em 0.1em !important;
  background-color: #f0f0f0;
  font-family: "Roboto";
}

.list-column{
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  list-style: none;
  font-family: "Roboto";
  font-size: 13px;
}
.list-column li::before {
  content: "\2022";
  color: red;
  font-weight: bold;
  display: inline-block; 
  width: 15px;
  font-size: 25px;
  position: relative;
  top: 4px;
}

.swal2-popup {
  font-family: "Roboto";
}

.radioButtonStyle .MuiSvgIcon-root{
  width: 15px;
  height: 15px
}

.radioButtonStyle .MuiTypography-root{
 font-size: 12px;
}
.radioButtonStyle .MuiButtonBase-root{
  padding: 2px;
 }

 .calendarStyle .MuiBox-root {
  top: -20px
 }